import React from 'react';
import styles from './Template.module.scss';

const Tags = ({ tags = [] }) => {
  const countTags = tags.length;
  return (
    <ul className={styles.tags}>
      {tags.map((tag, index) => {
        return (
          <li key={tag}>
            {countTags === (index + 1) ? tag : `${tag},`}
            &nbsp;
          </li>
        );
      })}
    </ul>
  );
};

export default Tags;

import React, { useState } from 'react';
import _ from 'lodash';
import styles from './MansonryFilter.module.scss';
import TemplateCard from '../TemplateList/TemplateCard';


const DISPLAY_ALL = 'Tous';
const Filter = ({
  filterName,
  categories, selectCategory, selectedCategory, resetCategory,
}) => {
  const resetCategoryButton = (
    <li key="resetCategory" onClick={resetCategory} aria-hidden className={selectedCategory.length === categories.length ? styles.selectedCategory : ''}>
      {DISPLAY_ALL}
    </li>
  );
  const displayButtons = categories.map((category) => {
    const handleClick = () => {
      selectCategory(category);
    };
    return (
      <li key={category} onClick={handleClick} aria-hidden className={_.includes(selectedCategory, category) ? styles.selectedCategory : ''}>
        {category}
      </li>
    );
  });

  displayButtons.splice(0, 0, resetCategoryButton);
  return (
    <div>
      <h2>{filterName}</h2>
      <ul>
        {displayButtons}
      </ul>
    </div>
  );
};
const MansonryFilter = ({ postEdges }) => {
  const postList = [];
  postEdges.forEach((postEdge) => {
    postList.push({
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      category: postEdge.node.frontmatter.category,
      cover: postEdge.node.frontmatter.cover,
      color: postEdge.node.frontmatter.color,
      title: postEdge.node.frontmatter.title,
      date: postEdge.node.fields.date,
      excerpt: postEdge.node.excerpt,
      timeToRead: postEdge.node.timeToRead,
    });
  });


  const categories = _.uniq(_.reduce(postList, (result, solution) => {
    result.push(solution.category);
    return result;
  }, []));
  const tags = _.uniq(_.reduce(postList, (result, solution) => {
    return result.concat(solution.tags);
  }, []));

  const [selectedCategory, setSelectedCategory] = useState(categories);
  const [selectedTags, setSelectedTag] = useState(tags);

  const reset = (selector, initialSet) => {
    return () => {
      return selector(initialSet);
    };
  };

  const toggle = (selector, initialSet) => {
    return (tag) => {
      return selector((lstate) => {
        if (lstate.length === initialSet.length) {
          return [tag];
        }
        const [...lstatecopy] = lstate;

        const index = lstatecopy.findIndex((t) => {
          return t === tag;
        });
        if (index >= 0) {
          lstatecopy.splice(index, 1);
        } else {
          lstatecopy.push(tag);
        }
        return lstatecopy;
      });
    };
  };
  const templatecards = postList.map((template) => {
    if (_.intersection(template.tags, selectedTags).length > 0 && _.includes(selectedCategory, template.category)) {
      return (
        <TemplateCard
          key={template.title}
          url={template.path}
          title={template.title}
          tags={template.tags}
          category={template.category}
          cover={template.cover}
          color={template.color}
        />
      );
    }
    return null;
  });
  const noResults = <span>Aucun modèle ne correspond aux filtres sélectionnés.</span>;
  return (
    <div className={styles.mansonryFilter}>
      <div className={styles.filters}>
        <Filter
          filterName="Services"
          categories={categories}
          selectCategory={toggle(setSelectedCategory, categories)}
          selectedCategory={selectedCategory}
          resetCategory={reset(setSelectedCategory, categories)}
        />
        <Filter
          filterName={"Secteurs d'activité"}
          categories={tags}
          selectCategory={toggle(setSelectedTag, tags)}
          selectedCategory={selectedTags}
          resetCategory={reset(setSelectedTag, tags)}
        />
      </div>
      <div className={styles.solutions}>
        {templatecards}
        {!_.some(templatecards, (v) => {
          return v !== null;
        }) && noResults}
      </div>
    </div>
  );
};


export default MansonryFilter;

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Jumbotron from '../components/Jumbotron/Jumbotron';
import MansonryFilter from '../components/MansonryFilter/MansonryFilter';
import Layout from '../layout';
import config from '../../data/SiteConfig';

class SolutionsPage extends Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <div className="containerPadding">
          <Helmet title={`About | ${config.siteTitle}`} />
          <Jumbotron
            headline1="A chaque équipe sa solution"
            subtitle="Pour commencer, voici une série d'exemples et de modèles qui vous permettrons de voir comment nos
              utilisateurs utilisent Spreadsheep pour résoudre leurs problèmes"
          />
          <div className="pageHeader" />

          <MansonryFilter postEdges={data.allMarkdownRemark.edges} />
        </div>
      </Layout>
    );
  }
}

export default SolutionsPage;


/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query templateQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
      filter: {frontmatter:{pagetype:{eq: "template"}} }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            category
            cover
            color
            date
          }
        }
      }
    }
  }
`;

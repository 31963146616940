import React from 'react';
import { Link } from 'gatsby';

import Tags from './Tags';

import styles from './Template.module.scss';
import cardIcon from '../../../content/img/job-recruiting.svg';

import colors from '../../scss/_colors.scss';

const defaultBackgroundColor = colors.blueDark; // $blue-dark
const TemplateCard = ({
  title, category, tags, color,
  url,
}) => {
  return (
    <div className={styles.card}>
      <Link to={url}>
        <div className={styles.panelHeading} style={{ backgroundColor: colors[color] || defaultBackgroundColor }}>
          <span className={styles.category}>{category}</span>
          <img src={cardIcon} alt={`category: ${category}`} />
        </div>
        <div className={styles.panelBody}>
          <h2>{title}</h2>
          <Tags tags={tags} />
        </div>
      </Link>
    </div>
  );
};


export default TemplateCard;
